<template>
  <div class="dialog">
    <div class="dialog-content">
      <img class="close" @click="close" :src="closeIcon" />
      <div class="form">
        <van-field v-model="code" type="number" center maxlength="4" autocomplete="one-time-code" placeholder="请输入短信验证码"
          class="input-code" @input="handleCode">
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>
            </div>
          </template>
        </van-field>
      </div>
      <div class="info">
        <slot name="info">
          <div>尊敬的客户，您正在办理：</div>
          <div>业务名称：随心选会员合约（电渠专属）</div>
          <div>
            业务内容：<span class="orange">10GB通用流量，黄金会员3元版，爱奇艺、腾讯视频、优酷视频、PP视频、芒果TV、哔哩哔哩月会员六项权益任选其一</span>
          </div>
          <div>业务资费：<span class="orange">30元/月</span></div>
          <div>合约期：<span class="orange">6个自然月（含当月）</span></div>
          <div>
            生效及订购方式：首月立即一次性扣费，次月起每月初按月一次性扣费。<span class="orange">活动到期后两款产品按标准价20元/月、10元/月自动续订。</span>
          </div>
          <div>活动资费介绍：</div>
          <div>
            1、合约限制：合约期内需<span class="orange">承诺在网</span>并履行约定，如申请<span class="orange">停机保号、销号、携号转网</span>等服务请至当地<span
              class="orange">移动自营厅</span>解约后办理，解除合约按河南移动合约活动<span class="orange">提前解约</span>方案执行。
          </div>
          <div>
            2、退订规则：合约期<span class="orange">最后5天前</span>不可取消视频随心选E版（无流量版）、10元畅享流量包，到期后两款产品按<span
              class="orange">标准价20元/月、10元/月自动续订</span>，您可根据到期提醒短信主动退订产品，不退订视为继续使用。合约到期后如您不再使用，可发送<span
              class="orange">3904到10086</span>退订视频随心选E版（无流量版），同步退订10元畅享流量包及黄金会员3元版，视频随心选E版（无流量版）退订<span
              class="orange">次月生效</span>，同步的流量包和黄金会员在退订当月月底生效。
          </div>
          <div>
            3、10元畅享流量包含10GB通用流量，12个月黄金会员3元版（2023年12月12日起新办理可享，截止时间以公告为准），到期自动退订，若转出则该优惠取消，若有互斥则不再享受。
          </div>
          <div>
            4、流量有效期：首月流量<span class="orange">立即到账</span>，有效期至次月底，次月起流量<span class="orange">每月初到账</span>，当月有效。
          </div>
          <div>
            5、流量优先级：流量小时套餐、流量日套餐、流量直充小时套餐、流量直充日套餐
            (同时订购多个日套餐或小时套餐时，失效时间最早的使用优先级最高)>假日流量套餐>定向流量>结转流量>校园小区流量>夜间流量套餐>基础套餐流量>手机上网套餐流量>加油包/流量直充月包>流量季包/半年包>赠送的免费流量>流量自动循环叠加包。若为副卡，则按照上述规则先使用副卡自身流量，再使用主卡可共享流量。
          </div>
        </slot>
      </div>
      <div class="checked">
        <van-checkbox :checked-color="checkedColor" v-model="checked" :name="true" icon-size="14px">
          我已阅读并同意上述内容
        </van-checkbox>
      </div>

      <div class="footer">
        <div class="footer-item" @click="close">
          <img :src="closeBackground" width="100%" />
        </div>
        <div class="footer-item" @click="handleSubmit">
          <img :src="submitBackground" width="100%" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVerificationCode, hnPhoneCheck } from "@/api/qiuMoFusion/index.js";
import { liuliangguo } from "@/utils/common";

export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => { },
    },
    checkedColor: {
      type: String,
      default: "#ffea00",
    },
    closeBackground: {
      type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/5Gpackage3/erqueRefuse.png",
    },
    submitBackground: {
      type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/5Gpackage3/erqueSubmit.png",
    },
    closeIcon: {
      type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd_henan_dialog_close.png",
    },
    needJump: Boolean, // 是否需要在失败过跳转尾量链接
  },
  data() {
    return {
      phone: this.form.phone.slice(0, 3) + '****' + this.form.phone.slice(7),
      code: this.form.code,
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      checked: false,
    };
  },
  methods: {
    async getHnPhoneCheck(phone) {
      const { data = "" } = await hnPhoneCheck(phone);
      // if (data.indexOf("移动") >= 0) {
      //   if (data.indexOf("郑州") >= 0) {

      //   }
      // } 
    },
    handleCode() {
      this.$emit("update:form", {
        ...this.form,
        code: this.code,
      });
    },
    getAuthCode() {
      if (this.yzm_loading) return;
      this.yzm_loading = true;
      try {
        getVerificationCode(
          JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
        )
          .then((res) => {
            this.yzm_loading = false;
            if (res.code != 0) {
              this.$dialog.alert({ message: res.message || '验证码获取失败' });

              liuliangguo("103697", this.form.phone, "随心选会员合约（电渠专属）");
            } else {
              // this.$parent.handleJump();
              this.$emit("update:form", {
                ...this.form,
                data: res.data.data.data,
                orderId: res.data.data.orderId,
              });
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          })
          .catch((error) => {
            this.yzm_loading = false;
          });
      } finally {
        this.yzm_loading = false;
      }
    },
    close() {
      this.$emit("update:visibility", false);
    },
    handleSubmit() {
      var filtercode = /^\d{4}$/;
      if (!this.form.code) {
        this.$toast.fail({
          message: "请输入验证码",
        });
      } else if (!filtercode.test(this.form.code)) {
        this.$toast.fail({
          message: "请输入4位验证码",
        });
      } else if (!this.checked) {
        this.$toast.fail({
          message: "请同意并勾选协议",
        });
      } else {
        this.$parent.submitFrom();
      }
    },
    showAlert() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "个人信息收集证明",
        message: `
《关于客户个人信息收集、使用及保护的公告》
        尊敬的客户：
您好！
根据《中华人民共和国个人信息保护法》、《中华人民共和国数据安全法》、《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在移动公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话卡（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名、证件类型、证件号码等。同时，为更好地提供服务，可能需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定向流量包类业务办理、权益+流量包办理、语音短信这类业务办理服务时，我们可能需要收集和使用您的手机号码、短信验证码，确保成功为您办理业务。
上述数据会提供给我们的合作运营商中国移动，用于为您办理业务。在接受和使用中国移动通信有限公司销售分公司的服务和产品前，您应了解中国移动通信有限公司销售分公司的《隐私和信息保护政策》（完整政策可查看网页https://dev.coc.10086.cn/coc/web2/Agreement/），同意本文件即视为您已了解并同意中国移动通信有限公司销售分公司的隐私和信息保护政策。
客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，中国移动有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
1）事先获得客户的明确授权；
2）根据有关的法律法规要求；
3）按照相关司法机关和/或政府主管部门的要求；
4）为维护社会公众的利益所必需且适当；
5）为维护我公司或客户的合法权益所必需且适当；
6）为确保您能成功办理中国移动独立或与我们合作的运营活动，我们会将您提交的信息提供给中国移动。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  &-content {
    // height: 693px;
    width: 700px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #7f4b98;

    .close {
      position: absolute;
      top: -70px;
      right: 14px;
      width: 40px;
      height: 40px;
    }

    .checked {
      margin: 40px 0 0 0;
      text-align: center;

      ::v-deep .van-checkbox {
        width: auto;
        justify-content: center;

        &__label {
          font-size: 26px;
          font-weight: 400;
          color: #ffffff;
        }

        &__icon {
          height: auto;
        }

        &__icon--checked .van-icon {
          padding-top: 3px;
          color: #ffffff;
        }
      }
    }

    .form {
      ::v-deep .van-cell {
        width: 562px;
        height: 86px;
        background-color: #ffffff;
        border-radius: 53px;
        margin: 30px auto 0;
        overflow: hidden;
        padding: 0 0 0 32px;

        &.input-code {
          margin-top: 80px;

          .code {
            width: 220px;
            height: 46px;
            text-align: center;
            line-height: 46px;
            color: #ff7900;
            font-size: 28px;
            font-weight: normal;
            border-left: 1px solid #ff7900;
          }
        }

        .van-field__body {
          height: 100%;

          input {
            height: 100%;
          }
        }

        &::after {
          border: none;
        }

        .van-field__control {
          &::-webkit-input-placeholder {
            font-size: 28px;
            font-weight: normal;
            color: #969696;
          }
        }
      }
    }

    .info {
      width: 600px;
      height: 458px;
      font-size: 22px;
      margin: 18px auto 0;
      overflow-y: auto;
      padding: 0px 0 15px 22px;
      box-sizing: border-box;
      border-radius: 12px;
      line-height: 50px;
      transform: translateY(20px);
      color: #ffffff;

      div {
        font-weight: 400;
      }

      .orange {
        color: #ffff00;
      }
    }

    .footer {
      display: flex;
      justify-content: space-evenly;
      padding: 30px 0;

      &-item {
        width: 278px;
        height: 140px;
        display: inline-block;

        &:last-child {
          animation: identifier 0.5s ease 0s infinite normal none running;

          @keyframes identifier {
            0% {
              transform: scale(1);
            }

            50% {
              transform: scale(1.1);
            }

            100% {
              transform: scale(1);
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
