<template>
  <div class="dialog">
    <div class="dialog-content" :style="style">
      <img
        class="close"
        @click="close"
        src="https://agentcdn.freelynet.com/index/image/zm/new_hn_5g/close.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd_heNan_02.png",
    },
  },
  computed: {
    style() {
      return {
        background: `url(${this.background}) 100% top/contain no-repeat`,
      };
    },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  &-content {
    height: 750px;
    width: 600px;
    margin: 0 auto;
    // padding-left: 100px;
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd_heNan_02.png")
      100% top/contain no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close {
      width: 60px;
      display: block;
      margin: 800px auto 0;
    }
  }
}
</style>
