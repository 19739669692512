<template>
  <div class="container">
    <img class="call-icon" @click="customerVisibility = true"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/15yuanEnjoy40G_icon.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/suixxVip-top.png" />

    <van-form>
      <img class="step1" v-if="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入中国移动手机号码(已加密)" @input="changePhone" />
      <img class="submit" @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/submit_lijidinggou.png" />
    </van-form>

    <div class="info">
      <div class="title">活动规则</div>
      <div class="text">
        1、合约活动内容：参与活动时需要为您订购<span class="yellow">随心选会员合约（电渠专属）</span>活动，<span
          class="yellow">活动价30元/月，合约期6个自然月（含当月）首月立即一次性扣费，次月起每月初按月一次性扣费</span>。
      </div>
      <div class="text">
        2、包含资源：<span
          class="yellow">10GB通用流量，黄金会员3元版，爱奇艺、腾讯视频、优酷视频、PP视频、芒果TV、哔哩哔哩月会员六项权益任选其一</span>（由于权益方的内容调整，中国移动可能会对权益细则、收费标准、权益发放方式等进行修改、变更或下架调整，部分权益可能会终止服务，届时您可选择领取其他权益方提供的产品。当您选择订购此产品即表明您已知晓并接受本服务内容）。<span
          class="yellow">可前往中国移动APP-首页-权益领取页面领取权益。</span>
      </div>
      <div class="text">
        3、合约承诺及违约约定：合约期内需<span class="yellow">承诺在网</span>并履行约定，如申请<span class="yellow">停机保号、销号、携号转网</span>等服务请至当地<span
          class="yellow">移动自营厅</span>解约后办理，解除合约按河南移动合约活动<span class="yellow">提前解约</span>方案执行。
      </div>
      <div class="text">
        4、合约活动到期：合约期最后5天前不可取消视频随心选E版（无流量版）、10元畅享流量包，到期后两款产品按标准价20元/月、10元/月自动续订，您可根据到期提醒短信主动退订产品，不退订视为继续使用。续订期间<span
          class="yellow">视频随心选E版（无流量版）每月月初一次性扣费，10元畅享流量包按天按量扣费</span>。合约到期后如您不再使用，可发送3904到10086退订视频随心选E版（无流量版），同步退订10元畅享流量包和黄金会员3元版，视频随心选E版（无流量版）退订<span
          class="yellow">次月生效</span>，同步的流量包和黄金会员在退订当月月底生效。
      </div>
      <div class="text">
        5、10元畅享流量包含10GB通用流量，12个月黄金会员3元版（2023年12月12日起新办理可享，截止时间以公告为准），黄金会员到期自动退订，立即生效，若转出则该优惠取消，若有互斥则不再享受。
      </div>
      <div class="text">
        6、流量有效期：首月流量<span class="yellow">立即到账</span>，有效期至次月底，次月起流量每月初到账，当月有效。
      </div>
      <div class="text">
        7、流量优先级：流量小时套餐、流量日套餐、流量直充小时套餐、流量直充日套餐
        (同时订购多个日套餐或小时套餐时，失效时间最早的使用优先级最高)>假日流量套餐>定向流量>结转流量>校园小区流量>夜间流量套餐>基础套餐流量>手机上网套餐流量>加油包/流量直充月包>流量季包/半年包>赠送的免费流量>流量自动循环叠加包。若为副卡，则按照上述规则先使用副卡自身流量，再使用主卡可共享流量。
      </div>
      <div class="text yellow">权益领取说明</div>
      <div class="text">
        1、权益领取规则：本产品生效期间，每自然月1日起至自然月月底，客户可领取当期权益，未领取则次月不能补领；
      </div>
      <div class="text">
        2、视频会员权益领取范围：咪咕视频钻石会员月卡、爱奇艺黄金VIP月会员、优酷VIP月会员、哔哩哔哩月大会员、芒果TV月会员、腾讯视频月会员；以上会员权益均仅适用于电脑端、手机端以及IPAD端。
      </div>
      <div class="text">
        3、视频会员权益领取入口：产品订购生效后，登录“中国移动APP-我的-我的权益-产品权益一随心系“领取权益。请保持手机号码状态正常(避免如欠费、停机等)，避免权益领取失败。
      </div>
      <div class="text">
        4、黄金会员包含内容：含1GB国内流量券、50元终端购机券、会员优惠购、周四会员日权益、权益小福袋、福利社（具体权益内容以权益超市页面展示为准）。
      </div>
      <div class="text">
        5、黄金会员领取入口：产品订购生效后，登录“中国移动APP-我的-我的权益-产品权益“领取权益。请保持手机号码状态正常(避免如欠费、停机等)，避免权益领取失败。
      </div>
    </div>
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/suixxVip-qudao.png" />

    <!-- 客服弹窗 -->
    <v-dialog-customer-service v-if="customerVisibility" :visibility.sync="customerVisibility"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/suixxVip-call.png" />
    <!-- 确认订购 -->
    <v-dialog v-if="visibility" :visibility.sync="visibility" :form.sync="form" />
    <!-- 成功弹窗 -->
    <v-dialog-customer-service v-if="successVisibility" :visibility.sync="successVisibility"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/suixxVip-success2.png" />

    <!-- 二确 -->
    <BaseDialogConfirm :BaseDialogvisibility.sync="BaseDialogvisibility" :closeBtnImg="closeBtnImg"
      :submitBtnImg="submitBtnImg" :background="background" />
  </div>
</template>

<script>
import VDialogCustomerService from "./dialog/customerService.vue";
import VDialog from "./dialog/suixxVip";
import BaseDialogConfirm from "./dialog/confirm.vue";
import { tradeOrder, cityPhoneCheck } from "@/api/qiuMoFusion/index.js";
import tailJumpMixin from "@/utils/tailJump.js";
import { JuDuoDuo } from "@/utils/common.js";

export default {
  mixins: [tailJumpMixin],
  components: {
    VDialogCustomerService,
    VDialog,
    BaseDialogConfirm
  },
  data() {
    return {
      form: {
        productNo: "X11202405029001",
        phone: "",
        characteristic: "",
        marketUrl: "",
        sid: "",
        data: "",
        code: "",
        orderId: "",
      },
      num: 1,
      customerVisibility: false,
      successVisibility: false,
      BaseDialogvisibility: false,
      visibility: false,
      origin: "",
      closeBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_cancel.png",
      submitBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_ok.png",
      background: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_bg01.png",
    };
  },

  created() {
    const { origin } = location;
    this.origin = origin;
    // document.title = "随心选会员";
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    const stroagePhone = localStorage.getItem("phone");
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone: !!phone ? phone : stroagePhone !== "unll" ? stroagePhone : "",
    };
    localStorage.setItem("phone", this.form.phone);
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      JuDuoDuo("100023", phone, "随心选会员合约（电渠专属）");
      // liuliangguo("103696", phone, "随心选会员合约（电渠专属）");
    };
  },
  methods: {
    async getHnPhoneCheck(phone) {
      const { data = "" } = await cityPhoneCheck(phone);
      if (data.indexOf("移动") >= 0) {
        if (data.indexOf("郑州") >= 0) {
          this.BaseDialogvisibility = true;
        } else {
          this.handleSubmit();
        }
      }
    },
    changePhone(value) {
      if (value.length == 11) {
        localStorage.setItem("phone", value);
      }
    },
    onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.visibility = true;
      }
    },
    submitFrom() {
      this.getHnPhoneCheck(this.form.phone);
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.visibility = false;
        this.BaseDialogvisibility = false;
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || '提交失败',
          });
          JuDuoDuo("100023", this.form.phone, "随心选会员合约（电渠专属）");
          // liuliangguo("103695", this.form.phone, "随心选会员合约（电渠专属）");
        } else {
          // this.$dialog.alert({
          //   title: "提交成功",
          //   message: "具体结果，请以短信提示为准",
          //   messageAlign: "left",
          // });
          this.successVisibility = true;
          JuDuoDuo("100023", this.form.phone, "随心选会员合约（电渠专属）");
          // liuliangguo("103694", this.form.phone, "随心选会员合约（电渠专属）");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #da1240;

  .call-icon {
    position: fixed;
    right: 0px;
    top: 122px;
    width: 130px;
    z-index: 1;
  }

  .img {
    width: 100%;
  }

  ::v-deep .van-form {
    background: transparent;
    position: relative;
    padding: 80px 0 0px;
    position: relative;

    .step1 {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .step1 {
      transform: translate(80px, -80px);
    }

    .van-cell {
      width: 670px;
      height: 110px;
      background-color: #ffffff;
      border-radius: 80px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 40px;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 110px;
          text-align: center;
          line-height: 110px;
          color: #09002a;
          font-size: 28px;
          font-weight: normal;
          // border-left: 2px solid #d14c1e;
          background-color: #ffe27a;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        font-weight: 400;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #caccce;
        }
      }
    }

    .submit {
      width: 600px;
      display: block;
      margin: 40px auto 60px;
      animation: identifier 1.5s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        25% {
          transform: scale(1.15);
        }

        50% {
          transform: scale(1);
        }

        75% {
          transform: scale(1.15);
        }
      }
    }
  }

  .info {
    font-size: 24px;
    font-weight: 500;
    padding: 0 60px 100px;
    line-height: 40px;
    color: #ffffff;

    .title {
      text-align: center;
      opacity: 0.8;
      font-size: 36px;
      margin-bottom: 30px;
    }

    .call {
      font-size: 36px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }

    .yellow {
      color: #ffff00;
    }

    .text {
      font-size: 24px;
      font-weight: 500;
      line-height: 50px;
      opacity: 0.8;
    }
  }
}
</style>
